

/*
 *
 * RLStevenson CSS
 * ------------
 *
 * Built by ExchangeRate-API with Bootstrap - inspired by
 * Hyde (https://github.com/mdo/hyde).
 *
 *
 * CSS SECTIONS
 *
 * Defaults
 * Blog Content
 * Sidebar
 * Themes
 * CV Styling
 *
*/


/* DEFAULTS */


html, body {
  height: 100%
}
h1, h2, h3, h4 {
  font-family: Roboto, sans-serif;
}
p {
  font-family: Roboto, sans-serif;
  font-size: 1.2em;
}



/* BLOG CONTENT */


.content {
  padding-left: 5em;
}
@media (max-width: 768px) {
  .content {
    padding-left: 1em;
  }
}
.post {
  margin-bottom: 3em;
}
.post .post-heading {
  margin-bottom: 1em;
}
.post .post-heading h1 {
  margin-top: 1.4em;
}
.post h1 {
  font-size: 2em;
  margin-bottom: 0.2em;
  margin-top: 1em;
  font-weight: bold;
}
.post h2 {
  font-size: 1.8em;
  margin-bottom: 0.2em;
  margin-top: 1em;
  font-weight: bold;
}
.post .post-heading span {
  font-size:1.4em;
}
.next {
  margin-left:10px;
}
.previous {
  margin-right:10px;
}
img {
  max-width: 95%;
}


/* SIDEBAR */


.sidebar {
  padding: 2em;
  font-size: 1.3em;
  text-align: left;
}
.sidebar .sidebar-content {
  width:85%;
  margin:0 auto;
}
.sidebar .sidebar-freetext {
  margin-top: 1.5em;
}
.sidebar .sidebar-menus {
  padding-left: 0;
  margin-left: 0;
  margin-top: 1.5em;
}
.sidebar .sidebar-menus li {
  list-style: none;
  font-size:1em;
  padding-top: 0;
}
.sidebar .sidebar-recent {
  margin-top: 1em;
}
.sidebar .sidebar-menus li a {
  // text-decoration:underline;
}
.sidebar-image {
  width: 30%;
  color: inherit;
  margin-left: -10px;
}
@media (min-width: 768px) {
  .sidebar {
    top: 0;
    bottom: 0;
    left: 0;
    position: fixed;
  }
  .sidebar-content {
    bottom: 1em;
    left: 1em;
    right: 1em;
    position: absolute;
  }
  .sidebar .sidebar-content {
    width:75%;
    margin:0 auto;
  }
  .sidebar-image {
    width: 90%;
  }
}
@media (max-width: 992px) {
  .sidebar {
    font-size: 1em;
  }
}
@media (max-width: 1200px) {
  .sidebar {
    font-size: 1.1em;
  }
}
.sidebar h1 {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 0;
}
.sidebar li {
  font-size: 0.8em;
}
p.copyright {
  font-size: 0.5em;
  border-top: 1px dotted #ccc;
  padding-top: 1em;
  margin-top: 2em;
  margin-bottom: 0.1em;
}
p.attr {
  margin-top: 0;
  font-size: 0.5em;
}


/* THEMES */


/* Gulf Racing Scheme */
.scheme-gulfracing {
  .sidebar h1 {
    color: #1C1C28;
  }
  .sidebar {
    background-color: #85B2D3;
    color: #444;
  }
}
.scheme-gulfracing .sidebar a {
  color: #444;
}
.scheme-gulfracing .content a {
  color: #E75E16;
}
.scheme-gulfracing .post .post-heading span {
  color: #9a9a9a;
}

/* Flying Grizzly Theme */
.flying-grizzly .sidebar {
  background-color: #2d2d2d;
  color: #f2f0ec;
}
.flying-grizzly .sidebar .polychromy-link {
  color: #99cc99;
  text-decoration: none;
}
  .flying-grizzly .sidebar a {
    text-decoration: underline;
    color: salmon;
  }
.flying-grizzly .sidebar a {
  color: #99cc99;
  text-decoration: none;
}
.flying-grizzly .sidebar a:hover {
  text-decoration: underline;
  color: salmon;
}
.sidebar-social {
  ul {
    list-style: none;
    padding-left: 0;
    li {
      font-family: monospace;
      font-size: .9em;
      margin: 1em;
      display: inline;
      margin-left: 0;
      a span {
        color: #D2D2D2;
      }
    }
  }
}
.sidebar-menus li{
  display: block;
  padding-bottom: .5em;
  padding-top: .5em;
}
.flying-grizzly .content h6 {
  font-style: italic;
  color: #9A9A9A;
}
.flying-grizzly .content li {
  font-size: 1.7rem;
}
.flying-grizzly .content blockquote {
  p {
    font-size: 1.5rem;
    font-style: italic;
  }
}
.flying-grizzly .content .post-date a, {
  color: #2d2d2d;
}
.flying-grizzly .post-heading h1 {
  color: #483151;
  a {
    color: #483151;
  }
}
.flying-grizzly .content p a {
  text-decoration: underline;
  &.footnote {
    text-decoration: none;
  }
}
.flying-grizzly .content .footnotes {
  p, li {
    font-size: 1.4rem;
  }
}
.flying-grizzly .post .post-heading span {
  color: #9a9a9a;
}
.flying-grizzly .post .post-heading span.hl {
  color: #1779BA;
  text-decoration: underline;
  text-decoration-color: #1779BA;
  font-size: 1em;
}
.flying-grizzly .post {
  ul {
    list-style: none;
  }
  ul li {
    margin-bottom: .3em;
  }
  ul li:before {
    margin-left: -.7em;
    content: "-  ";
    font-weight: bold;
  }
  p.post-excerpt span.hl {
    color: #1779BA;
    text-decoration: underline;
    text-decoration-color: #1779BA;
    font-size: 1em;
  }
}
.flying-grizzly .image-wrapper {
  margin: 20px 0;
  border-bottom: 1px solid darkgrey;
  img {
    margin-bottom: 5px;
  }
}
.flying-grizzly .image-caption p {
  font-size: .9em !important;
  color: #9a9a9a !important;
}
.flying-grizzly table {
  margin-bottom: 1.5em;
}
.flying-grizzly .content td {
  vertical-align: top;
  padding: .5em;
  border: 1px solid #9A9A9A;
}
.flying-grizzly .callout {
  border: 1px solid #e7eef6;
  border-left: 2px solid RGB(54, 88, 54);
  // border-radius: 8px;
  background: #f3f6fa;
  padding: 10px;
  margin-bottom: 1.4rem;
  h6 {
    color: RGB(51, 51, 51);
    font-size: 1.2em;
    font-weight: 600;
    font-style: italic;
    margin-bottom: 0;
  }
}
.flying-grizzly {
  .videowrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
.flying-grizzly {
  .search-form {
    input {
      width: 95%;
      margin-bottom: 2em;
    }
  }
  #search-results {
    max-width: 100%;
  }
}
.flying-grizzly {
  .comment-divider {
    margin-bottom: 2em;
  }
  .comments {
    h2 {
      margin-bottom: 10px;
    }
    fieldset {
      border: 1px solid #e7eef6;
      border-radius: 3px;
      padding: 1em;
      legend {
        margin-bottom: -2px;
        border: none;
        width: auto;
        padding: 3px;
      }
    }
    form label {
      display: block;
      font-style: italic;
      input, textarea {
        font-style: normal;
        font-weight: normal;
        display: block;
        width: 100%;
        max-width: 100%;
      }
    }
    .comment {
      border-left: 5px solid #99CC99;
      padding: 3px;
      padding-top: 1px;
      padding-left: 8px;
      margin-bottom: 2em;
      margin-top: 2.5em;
      .comment__header {
        margin-left: .4em;
        &::after {
          content: '';
          display: block;
          width: 50%;
          border-bottom: 1px solid lightgray;
        }
        .comment__author-name {
          font-size: 1.5em;
          margin-bottom: 2px;
          margin-top: 8px;
        }
      }
      .comment__content {
        font-size: 1.1em;
        margin-top: .8em;
        margin-left: .6em;
      }
      .comment__reply {
        border-left: 5px solid red;
        padding-left: 10px;
        margin-bottom: .5em;
        .comment__reply {
          border-left: 5px solid #377BB5;
          .comment__reply {
            border-left: 5px solid #2D2D2D;
          }
        }
      }
      .comment__separator {
        margin-bottom: 0;
        margin-top: 0;
        color: transparent;
        &::after {
          content: '';
          display: block;
          width: 80%;
          border-bottom: 1px solid lightgrey;
        }
      }
    }
    .comment__id {
      font-size: 0;
      margin: 0;
      border: none;
      padding: 0;
      display: none;
    }
    .comment__reply-button {
      border: none;
      background: none;
      text-decoration: underline;
      color: #337AB7;
      font-style: italic;
      margin-left: 0;
    }
  }
}




/*
 * The original Hyde theme uses Base16 for colour schemes so
 * we decided to also use this project. We picked different
 * colors though. We also used the Ocean and 80's palettes in
 * addition to the Default palette.
 *
 * Base16: (http://chriskempson.github.io/base16)
 *
*/

/* Dark Brown Scheme */
.scheme-darkbrown .sidebar {
  background-color: #2d2d2d;
  color: #f2f0ec;
}
.scheme-darkbrown .sidebar h1 {
  color: #99cc99;
}
.scheme-darkbrown .sidebar a {
  color: #99cc99;
}
.scheme-darkbrown .content a {
  color: #2d2d2d;
}
.scheme-darkbrown .post .post-heading span {
  color: #9a9a9a;
}

/* Light Brown Scheme */
.scheme-lightbrown .sidebar {
  background-color: #d3d0c8;
  color: #393939;
}
.scheme-lightbrown .sidebar h1 {
  color: #393939;
}
.scheme-lightbrown .sidebar a {
  color: #515151;
}
.scheme-lightbrown .content a {
  color: #393939;
}
.scheme-lightbrown .post .post-heading span {
  color: #9a9a9a;
}

/* Orange Scheme */
.scheme-orange .sidebar {
  background-color: #f99157;
  color: #f2f0ec;
}
.scheme-orange .sidebar h1 {
  color: #515151;
}
.scheme-orange .sidebar a {
  color: #747369;
}
.scheme-orange .content a {
  color: #393939;
}
.scheme-orange .post .post-heading span {
  color: #9a9a9a;
}

/* Green Scheme */
.scheme-green .sidebar {
  background-color: #a3be8c;
  color: #4f5b66;
}
.scheme-green .sidebar h1 {
  color: #2b303b;
}
.scheme-green .sidebar a {
  color: #eff1f5;
}
.scheme-green .content a {
  color: #2d2d2d;
}
.scheme-green .post .post-heading span {
  color: #9a9a9a;
}

/* Slate Scheme */
.scheme-slate .sidebar {
  background-color: #a7adba;
  color: #343d46;
}
.scheme-slate .sidebar h1 {
  color: #2b303b;
}
.scheme-slate .sidebar a {
  color: #bf616a;
}
.scheme-slate .content a {
  color: #65737e;
}
.scheme-slate .post .post-heading span {
  color: #9a9a9a;
}

/* CV styles */
.cv {
  h2, h5 {
    text-decoration: underline;
  }
  h4 {
    margin-top: 1.5em;
    font-weight: 700;
  }
  strong {
    font-size: 1.8rem;
  }
  table {
    margin-top: 20px;
    margin-bottom: 45px;
  }
  td {
    vertical-align: bottom !important;
    padding-top: 20px !important;
    padding-right: 0 !important;
    &:nth-child(2n+1) {
      padding-left: 0 !important;
      padding-right: 5px !important;
    }
    font-size: 1.8rem;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
  }
  margin-bottom: 50px !important;
}

/* Series slug themes */
.series-slug {
  border: 1px solid #e7eef6;
  border-left: 2px solid #c8665b;
  // border-radius: 8px;
  background: #f3f6fa;
  padding: 10px;
  margin-bottom: 1.4rem;
  .series-title {
    margin-top: 7px;
    font-weight: bold;
    font-size: 1.2em;
    color: #365836;
    // border-bottom: 1px solid RGB(145, 99, 163);
  }
  h4 {
    font-size: 1.2em;
    font-weight: 600;
    font-style: italic;
    margin-bottom: 0;
  }
  ul {
    list-style-type: none;
    padding-left: 1.5em;
    li:before {
      content: '&#187;   ';
      font-size: 1.4em;
      margin-left: -.8em;
    }
    a {
    // color: ;
    text-decoration: none;
    font-size: 1.2em;
      &:hover {
      text-decoration: underline;
      color: #6b8e6b;
      }
    }
  }
}

ul.release-notes-links {
  list-style-type: none;
  padding-left: 1.5em;
  li:before {
    content: '&#187;   ';
    font-size: 1.4em;
    margin-left: -.8em;
  }
}

#search-form {
  margin-top: 2em;
  #q {
    border-radius: 3px;
    border: 1px solid gray;
    padding: 3px;
  }
  #q-submit {
    border: 1px solid darkgray;
    border-radius: 3px;
    // background: #99cc99;
    color: black;
    font-weight: 400;
    margin-left: 3px;
  }
}
